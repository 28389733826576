<template>
  <nav class="navbar navbar-expand-lg text-uppercase" id="topNav">
    <div class="container d-flex flex-wrap justify-content-end">
      <ul class="nav">
        <li class="nav-item mx-0 mx-lg-1">
          <i class="fa-brands fa-line"></i>&nbsp;<a href="https://lin.ee/4wjO2dx">@vmplastic</a>&nbsp;&nbsp;
        </li>
        <li class="nav-item mx-0 mx-lg-1">
          <i class="fa-solid fa-envelope"></i>&nbsp;info@vmplastic.com&nbsp;&nbsp;
        </li>
        <li class="nav-item mx-0 mx-lg-1">
          <i class="fa-solid fa-phone"></i>&nbsp;<a href="tel:081-826-4289">081-826-4289</a>
        </li>
      </ul>
    </div>
  </nav>
  <nav class="navbar navbar-expand-lg text-uppercase" id="mainNav">
    <div class="container d-flex flex-wrap">
      <a class="navbar-brand js-scroll-trigger " href="#">
        <!-- <img src="/assets/logo.png" class="img-fluid img-logo"> -->
        <h2><strong class="title-ping">V.M.PLASTIC</strong></h2>
      </a>
      <button class="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation"
        v-on:click="clickToggle()"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse offcanvas-collapse" id="navbarResponsive" :class="{open: isToggle}">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item mx-0 mx-lg-1" id="nav-item-home" v-on:click="clickToggle()" 
            :class="{active:currentRouteName == 'HomeComponent'}">
            <a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="/"><strong>Home</strong></a>
          </li>          
          <li class="nav-item mx-0 mx-lg-1" id="nav-item-products" v-on:click="clickToggle()"
            :class="{active:currentRouteName == 'ProductsComponent'}">
            <a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="/products"><strong>Products</strong></a>
          </li>
          <li class="nav-item mx-0 mx-lg-1" id="nav-item-promotion" v-on:click="clickToggle()"
            :class="{active:currentRouteName == 'PromotionComponent' || currentRouteName == 'PromotionDetailComponent'}">
            <a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="/promotion"><strong>Promotion</strong></a>
          </li>          
          <li class="nav-item mx-0 mx-lg-1" id="nav-item-about" v-on:click="clickToggle()"
            :class="{active:currentRouteName == 'AboutComponent'}">
            <a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="/about"><strong>About</strong></a>
          </li>                  
          <li class="nav-item mx-0 mx-lg-1" id="nav-item-contact" v-on:click="clickToggle()">
            <a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="/#contact"><strong>Contact</strong></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavbarSection',
  data() {
    return {
      isToggle: false
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    toggleCollapse() {
      this.isToggle = !this.isToggle
    },
    clickToggle() {
      this.toggleCollapse()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #topNav {
    background-color: #7757A5;
    color: #FFFFFF;
    font-size: 12px;
    padding: 5px;
  }
  #mainNav ul {
    font-size: 14px;
  }
  #navbarResponsive {
    z-index: 100;
  }
  /* Navbar */
  @media (max-width: 991.98px) {
    .offcanvas-collapse {
      position: fixed;
      top: 85px; /* Height of navbar */
      bottom: 0;
      left: 100%;
      width: 100%;
      padding-right: 1rem;
      padding-left: 1rem;
      overflow-y: auto;
      visibility: hidden;
      background-color: #FFFFFF;
      transition: transform .3s ease-in-out, visibility .3s ease-in-out;
    }
    .offcanvas-collapse.open {
      visibility: visible;
      transform: translateX(-100%);
    }
  }
  #topNav .nav-item a {
    color: #FFFFFF;
    text-decoration: none;
  }
  .nav-scroller .nav {
    color: rgba(255, 255, 255, .75);
  }
  .nav-scroller .nav-link {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: .875rem;
    color: #6c757d;
  }
  .nav-scroller .nav-link:hover {
    color: #007bff;
  }
  .nav-scroller .active {
    font-weight: 500;
    color: #343a40;
  }
  .bg-purple {
    background-color: #6f42c1;
  }
  .navbar-nav .active strong {
    border-bottom: 5px solid #7757A5;
  }
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  /* Navbar */
</style>