<template>
    <section class="footer" id="footer">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-3 border-right">
                    <img src="/assets/logo_hover.png" class="img-fluid img-logo">
                </div>
                <div class="col-4 border-right">
                    <div class="row">
                        <div class="col-12">
                            <strong>SITEMAP</strong>
                        </div>
                    </div>
                    <div class="row link-site-map">
                        <div class="col-12">
                            <a href="/">HOME</a>
                        </div>
                    </div>
                    <div class="row link-site-map">
                        <div class="col-12">
                            <a href="/products">PRODUCTS</a>
                        </div>
                    </div>
                    <div class="row link-site-map">
                        <div class="col-12">
                            <a href="/promotion">PROMOTION</a>
                        </div>
                    </div>
                    <div class="row link-site-map">
                        <div class="col-12">
                            <a href="/about">ABOUT US</a>
                        </div>
                    </div>
                    <div class="row link-site-map">
                        <div class="col-12">
                            <a href="#">CONTACT</a>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <div class="row">
                        <div class="col-12">
                            <strong>CONTACT</strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <span>บริษัท วี.เอ็ม. ผลิตภัณฑ์พลาสติก จำกัด</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <span>109/16-17 หมู่ที่ 5 ต.คลองมะเดื่อ อ.กระทุ่มแบน จ.สมุทรสาคร 74110</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <a href="https://lin.ee/4wjO2dx" target="_blank">
                                <i class="fa-brands fa-line"></i>&nbsp;@vmplastic 
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <a href="#" target="_blank">
                                <i class="fa-solid fa-envelope"></i>&nbsp;info@vmplastic.com
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <a href="#" target="_blank">
                                <i class="fa-brands fa-square-facebook"></i>&nbsp;facebook.com/vmplastic
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <a href="tel:081-826-4289" target="_blank">
                                <i class="fa-solid fa-phone"></i>&nbsp;081-826-4289
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="copy-right" id="copy-right">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-12">@ 2023 VMPLASTIC.COM All rights reserved.</div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'FooterSection'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #footer {
        color: #FFFFFF;
        background-color: #7757A5;
    }
    .border-right {
        border-right: 1px solid;
    }
    #footer > .container {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    #footer a {
        color: #FFFFFF;
        text-decoration: none;
    }
    #copy-right {
        background-color: #777777;
        color: #FFFFFF;
    }
    .link-site-map {
        padding-left: 5px;
        margin-bottom: 5px;
    }
    #copy-right .container .row {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .mobile-icon-phone, .mobile-line-logo, .mobile-line {
        font-size: 30px;
    }
    .mobile a {
        color: #FFFFFF;
        text-decoration: none;
    }

    @media only screen and (max-width: 768px) {

        .mobile {
            background-color: #a449ff;
            display: flex;
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            color: #FFFFFF;
            padding-top: 10px;
            padding-bottom: 10px;
            z-index: 10000 !important;
        }
        .not-mobile {
            display: none;
        }
        #footer {
            padding-bottom: 50px;
        }
    }
    @media only screen and (min-width: 768px) {
        .mobile {
            display: none;
        }
        .not-mobile {
            display: flex;
        }
    }
</style>
